<template>
  <div>
    <div class="content">
      <div class="contentL">
        <div class="contentLtitle">变配电站</div>
        <!-- <el-input class="inputValue" v-model="inputValue" placeholder="请输入关键词搜索" @click="handleSearch"
          suffix-icon="el-icon-search">
        </el-input> -->
        <div class="tree-container">
          <el-tree ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData" :check-on-click-node="false
            " :props="defaultProps" node-key="id" @node-click="handleCheck"></el-tree>
        </div>

      </div>
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">环比分析</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="newView">
              <!-- <el-button type="primary" @click="openViewDialog" icon="el-icon-plus" class="custom-button">新建公告</el-button> -->

              <div class="day" v-for="(item, index) in dayList" :key="index" :class="day == index ? 'day1' : ''"
                @click="dayFn(index)">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="日期：">
                  <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input"
                    v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
                  <el-button type="primary" @click="downLoad" icon="el-icon-download" class="custom-button">导出</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView1">

            </div>

          </div>


        </div>

        <div class="table">
          <el-table border :data="tableData" style="width: 100%" class="custom-table">
            <el-table-column align="center" label="序号" type="index" width="200">
            </el-table-column>
            <el-table-column align="center" prop="name" label="回路名称" :fluid="true">
            </el-table-column>
            <el-table-column align="center" prop="emissions" label="当日碳排/tCO2e" width="200">
            </el-table-column>
            <el-table-column align="center" prop="emissions1" label="上日碳排/tCO2e" width="200">
            </el-table-column>
            <el-table-column align="center" prop="emissions2" label="上日碳排/tCO2e" width="200">
            </el-table-column>
            <el-table-column align="center" prop="emissions2" label="环比(%)" width="200">
              <template slot-scope="scope">
                  <img class="tableIcon" v-show="scope.row.type == 0" src="../../assets/images/analysis/decline.png" alt="">
                <img class="tableIcon" v-show="scope.row.type == 1" src="../../assets/images/analysis/rise.png" alt="">
              </template>
            </el-table-column>
            <el-table-column align="center" prop="emissions2" label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="primary" @click="openTableDialog" class="custom-table-button">图表</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div class="btn mouseCursor" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes"
            :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
            next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm mouseCursor" @click="jumpPage">确定</div>
          </div>
        </div>
      </div>

      <!-- 图表 -->
      <el-dialog title="主回路" :visible.sync="replyDialogVisible" width="30%">
        <div class="chartsTitle">增长值20kw·h 增长率10%</div>
        <div class="newCenter">
          <div id="myEcharts" class="myEcharts"></div>
        </div>
      </el-dialog>

      <!-- 导出 -->
      <el-dialog title="下载" :visible.sync="downLoadDialogVisible" width="20%">
      <div class="deleteCenter">
        确定下载该文件?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="downLoadDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="downLoadDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    </div>



  </div>
</template>

<script>
import { boardList } from '../../api/dailyWork.js'

import * as echarts from "echarts";
export default {
  name: 'Administration',
  data() {
    return {
      formInline: {
        title: '',//标题
        content: '',//内容
        createBy: '',//创建人
        updateTime: '',//创建时间
      },
      replyDialogVisible: false,
      downLoadDialogVisible:false,
      showType: 0,
      tableData: [
        {
          name: '碳排回路',
          number: 15,
          emissions: '0.00',
          emissions1: '0.00',
          emissions2: '0',
          type: 0
        },
        {
          name: '碳排回路',
          number: 15,
          emissions: '0.00',
          emissions1: '0.00',
          emissions2: '0',
          type: 1
        },

      ],
      textarea: '',

      treeData: [

        {
          id: 11,
          label: '高压侧',
          children: [
            { id: 111, label: '配电室' },
            { id: 112, label: '箱变' },
            { id: 113, label: '柱变' },
            {
              id: 114, label: '强电间',
              children: [
                { id: 1141, label: '高压侧' },
                { id: 1142, label: '低压侧' },
                { id: 1143, label: '变压器' },
                { id: 1144, label: '直流屏' },
              ]
            },
          ],
        },
        {
          id: 12,
          label: '箱变',
          children: [
            { id: 121, label: '配电室' },
            { id: 122, label: '箱变' },
            { id: 123, label: '柱变' },
            { id: 124, label: '强电间', }

          ]

        },
        {
          id: 13,
          label: '柱变',
          children: [
            { id: 131, label: '配电室' },
            { id: 132, label: '箱变' },
            { id: 133, label: '柱变' },
            { id: 134, label: '强电间', }

          ]

        },
        {
          id: 14,
          label: '强电间',
          children: [
            { id: 141, label: '配电室' },
            { id: 142, label: '箱变' },
            { id: 143, label: '柱变' },
            { id: 144, label: '强电间', }

          ]

        },


      ],
      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      defaultProps: {
        children: 'children',
        id: 'id',
        label: 'label'
      },
      dayList: ['按日', '按周', '按月',],
      day: 0,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: 40,
      textarea: '',

    };
  },
  // components:{demo},

  mounted() {


  },
  computed: {

  },


  methods: {
    downLoad(){
      this.downLoadDialogVisible=true
    },

    openTableDialog() {
      this.replyDialogVisible = true
      this.$nextTick(() => {

        this.drawEnergy()
      });

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      // this.paginationEndIpt=''
    },
    dayFn(el) { this.day = el },
    // tree搜索
    openViewDialog(el) {
      this.showType = el
    },
    handleSearch() {

    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    //列表查询
    getList() {
      let obj = {
        ...this.formInline,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      boardList(obj).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    //查看
    replyDialog(el) {
      console.log(el.data);
      this.centerDialogVisible = true
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
    },

    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    },
    drawEnergy() {
      var myEcharts = echarts.init(document.getElementById("myEcharts"));

      var option = {
        grid: {
          left: '1%',
          right: '1%',
          containLabel: true
        },
        legend: {
          data: ['上日用电', '当日用电',],

          textStyle: {
            color: "#AED6FF"
          }
        },
        toolbox: {

          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: '区域缩放',
                back: '区域缩放还原'
              },
              iconStyle: {
                color: '#071B42',
                borderColor: '#ffffff',
                borderWidth: 1
              }
            },

            restore: {
              show: true,
              title: '还原',
              iconStyle: {
                color: '#071B42',
                borderColor: '#ffffff',
                borderWidth: 1
              }
            },
            saveAsImage: {
              show: true,
              title: '下载',
              iconStyle: {
                color: '#071B42',
                borderColor: '#ffffff',
                borderWidth: 1
              }
            }
          },
          right: '4%',
          top: '1%'
        },

        dataZoom: {
          show: true,
          type: 'slider',
          start: 0,
          end: 100, // 默认显示前50%的数据
          handleSize: 20,
          handleStyle: {
            color: '#61a0a8'
          },

          textStyle: {
            color: '#ffffff'
          },
          orient: 'vertical', // 将滑动条放置在右侧
          right: 10, // 设置滑动条距离容器右侧的距离
          top: 50, // 设置滑动条距离容器顶部的距离
          width: 20 // 设置滑动条的宽度
        },
        backgroundColor: '#071734',
        tooltip: {},
        dataset: {
          source: [
            ['product', '上日用电', '当日用电',],
            ['主回路', 43.3, 35.8,],
          ]
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
            show: false
          },
          axisLabel: {
            fontSize: 16, // 设置标签的字体大小
            textStyle: {
              color: '#aed6ff' // 设置 x 轴标签字体颜色
            }
          }
        },
        yAxis: {
          type: 'value',
          name: "单位：kw·h",
          nameTextStyle: {
            color: '#aed6ff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 
            }
          }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: 'bar',
            barGap: '100%',
            barWidth: '20', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [5, 5, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2'
            }

          },
          {
            type: 'bar',
            barGap: '100%',
            barWidth: '20', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [5, 5, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#2FC9CB'
            }

          },


        ]
      };

      myEcharts.setOption(option);
    }


  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/routerImg.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  width: 100%;
  height: 400px;
  display: flex;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}



::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  display: flex;

}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  color: #ffc74a;
  background-color: #002C6C;
}

.day1 {
  color: #ffc74a;
  background-color: #002C6C;

}

/* .mycharts {
  width: 100%;
  height: 800px;
} */

.titleEcharts {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}

::v-deep.el-tree {
  width: 300px;
}

.custom-button-yellow {
  background: #E0AB34;
  border-color: #E0AB34;
}

.custom-table-button>>>span {
  color: #ffffff;
}

.custom-table-button {
  background: #1474D3;
}

.myEcharts {
  width: 100%;
  height: 450px;
}

::v-deep .el-dialog__body {
  background: #071734;
}

::v-deep .el-dialog__footer {
  background: #071734;
}

::v-deep .el-dialog__header {
  background: #05153A;
  color: #c9d4f1;
}

::v-deep .el-dialog__title {

  padding-left: 15px;
  position: relative;
}

::v-deep .el-dialog__title::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  width: 3px;
  background-color: #176ad2;
  border-radius: 5px;
}




.chartsTitle {
  font-size: 18px;
  text-align: center;
  color: #aed6ff;
  padding-bottom: 20px;
}
.tableIcon{
  /* height: 20px; */
  width: 16px;
}



</style>